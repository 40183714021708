<template>
  <div v-html="renderedText"></div>
</template>
<script>
/**
 * SAFELY render markdown content to the DOM
 */
import DOMPurify from 'dompurify'
const md = require('markdown-it')()
export default {
  name: 'Markdown',
  props: {
    text: {
      required: true,
      type: String
    }
  },
  data: () => ({
    renderedText: null
  }),
  created () {
    // render the markdown into HTML and sanitize HTML using DOMPurify
    this.renderedText = DOMPurify.sanitize(md.render(this.text))
    this.$emit('parsed', this.renderedText)
  }
}
</script>
