
<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="10" lg="8" xl="6">

        <p class="text-h5">The below document is required by {{ organization }}</p>
        <p>
          <a @click="moreInfoDialog = true">
            <v-icon small>fa-info-circle</v-icon>
            Why is this document required?
          </a>
        </p>
        <v-dialog v-model="moreInfoDialog" max-width="600" :fullscreen="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <p class="text-body-1">
                A lien is a claim or perfected interest that {{ organization }} makes against your personal injury
                settlement or judgment to ensure that we receive payment for the services we have provided for your care.
                When another party is the "at-fault" party they are responsible for payment of your medical bills and injuries.
                Sometimes your injury claim will be prosecuted through the other driver's auto insurance carrier, or even your
                medical payments coverage or uninsured motorist auto insurance carrier. By signing this, you understand that
                in the event that you or your attorney receive money from a settlement, judgment, or award, for the injuries
                arising out of a personal injury claim or lawsuit, you hereby grant a lien for medical expenses to
                {{ organization }}.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="moreInfoDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <markdown-document v-if="document && showSignatureForm" :text="document" />

        <template v-if="showSignatureForm">
          <electronic-signature :loading="signing" :user-data="payload" @submit="handleSignatureSubmit" />
        </template>

        <p v-if="!showSignatureForm">Your signature has been successfully received. You can now close this window.</p>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MarkdownDocument from '../../../components/DocumentViewers/MarkdownDocument'
import ElectronicSignature from '../../../components/ElectronicSignature/ElectronicSignature'
import event, { Events } from '../../../event'

export default {
  name: 'SignLien',
  components: { MarkdownDocument, ElectronicSignature },
  data: () => ({
    moreInfoDialog: false,
    token: null,
    payload: null,
    document: null,
    signing: false,
    showSignatureForm: true
  }),
  computed: {
    organization () {
      return this.payload.organization
    }
  },
  created () {
    this.token = this.$route.query.token
    this.payload = JSON.parse(atob(this.token.split('.')[1]))

    this.getLienDocumentTemplate()
  },
  methods: {
    async getLienDocumentTemplate () {
      const response = await window.axios.get('/v1/case/lien/document-template?token=' + this.token)
      this.document = response.data.payload.template
    },

    // TODO: Check the block below
    async handleSignatureSubmit (form) {
      const formData = new FormData()
      formData.append('name', form.name)
      formData.append('email', form.email)
      formData.append('esignConsent', form.esignConsent)
      formData.append('signature', form.signature)
      formData.append('token', this.token)

      this.signing = true
      await window.axios.post('/v1/case/lien/sign', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).finally(() => {
        this.signing = false
      })

      event.emit(Events.SUCCESS, 'Signature successful')
      this.showSignatureForm = false
    }
  }
}
</script>
