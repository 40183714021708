<style scoped>
#signature-container {
  background-color: rgba(0, 0, 0, 0.06);
}

#signature-container:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

#signature-box {
  width: 100%;
  height: 100%;
}

#signature-pad {
  width: 100%;
  border: 1px solid rgba(0,0,0,.6);;
  background-color: white;
  border-radius: 4px;
}

#clear-button {
  position: absolute;
  right: 20px;
  top: 45px
}
</style>
<template>
  <v-responsive id="signature-container" :aspect-ratio="aspectRatio" class="pa-3 rounded" v-bind="$attrs">
    <slot name='title'><label :class="{'red--text': !!error }">signature</label></slot>
    <div ref="signatureBox" id='signature-box'>
      <canvas id="signature-pad" ref='signaturePad'></canvas>
      <v-btn icon @click='deleteSignature' id='clear-button'>
        <slot name='clear-icon'>
          <v-icon>fa-times</v-icon>
        </slot>
      </v-btn>
      <p v-if="error" class="red--text">{{ error }}</p>
    </div>
  </v-responsive>
</template>
<script>
import { fabric } from 'fabric'
export default {
  name: 'SignaturePad',
  props: {
    aspectRatio: {
      type: Number,
      required: false,
      default: 16 / 5
    },
    error: {
      type: String,
      required: false
    }
  },
  data: () => ({
    canvas: null
  }),
  mounted () {
    const container = document.getElementById('signature-box')

    this.canvas = new fabric.Canvas('signature-pad')
    this.canvas.setWidth(container.clientWidth)
    this.canvas.setHeight(container.clientHeight)
    this.canvas.isDrawingMode = true
    this.canvas.freeDrawingBrush.width = 1
    this.canvas.freeDrawingBrush.color = '#000000'
  },
  methods: {
    deleteSignature () {
      this.canvas.clear()
    },
    hasSignature () {
      return this.canvas.getObjects().length > 0
    },
    toDataURL () {
      return this.canvas.toCanvasElement().toDataURL()
    },
    toBlob () {
      return new Promise((resolve) => {
        this.canvas.toCanvasElement().toBlob(blob => {
          resolve(blob)
        })
      })
    }
  }
}
</script>
