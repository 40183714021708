<template>
  <div>
    <v-form ref="form" v-model="formValid">
      <v-checkbox v-model="form.esignConsent" :rules="[rules.required]">
        <template #label>
          <span>
            I agree to use electronic signature.
            View e-signature&nbsp;<a @click.stop.prevent="showEsignRules = true">rules</a>
          </span>
        </template>
      </v-checkbox>

      <!-- e-sign rules -->
      <v-dialog max-width="1000" v-model="showEsignRules" :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card>
          <v-card-title />
          <v-card-text>
            <electronic-signature-rules />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showEsignRules = false">close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <signature-pad ref="signature"
                     class="mb-6"
                     :aspect-ratio="$vuetify.breakpoint.smAndUp ? 16/3 : 16/5"
                     :error="signatureError" />

      <v-text-field v-model="form.name" label="full legal name" filled :rules="[rules.required]" />
      <v-text-field v-model="form.email" label="email" filled :rules="[rules.required]" />

      <v-btn color="primary" :loading="loading" @click="handleFormSubmit">Submit</v-btn>
    </v-form>
  </div>
</template>
<script>
import SignaturePad from '../../components/ElectronicSignature/SignaturePad'
import RulesMixin from '../../mixins/RulesMixin'
import ElectronicSignatureRules from './ElectronicSignatureRules'

export default {
  name: 'ElectronicSignature',
  components: { ElectronicSignatureRules, SignaturePad },
  mixins: [RulesMixin],
  props: {
    userData: {
      required: false,
      type: Object
    },
    loading: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    showEsignRules: false,
    signatureError: null,
    formValid: true,
    form: {
      name: null,
      email: null,
      esignConsent: false,
      signature: null
    }
  }),
  mounted () {
    this.form = { ...this.form, ...this.userData }
  },
  methods: {
    async handleFormSubmit () {
      this.$refs.form.validate()
      if (this.formValid !== true) {
        return
      }

      // check for a signature
      if (this.$refs.signature.hasSignature() === false) {
        this.signatureError = 'required'
        return
      }

      this.form.signature = await this.$refs.signature.toBlob()

      this.$emit('submit', this.form)
    }
  }
}
</script>
