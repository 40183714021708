<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn icon v-on="on" @click="downloadPdf">
        <v-icon small>fa-download</v-icon>
      </v-btn>
    </template>
    <span>Download</span>
  </v-tooltip>
</template>
<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'DownloadPdf',
  props: {
    format: {
      type: String,
      required: false,
      default: 'HTML'
    },
    content: {
      type: String,
      required: true
    }
  },
  methods: {
    downloadPdf () {
      html2pdf()
        .set({
          margin: 0.5,
          filename: 'lien agreement.pdf',
          jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: 'portrait'
          }
        })
        .from(this.html())
        .save()
    },
    html () {
      return `
<!DOCTYPE html>
<html lang="en">
<head>
  <title>Lien Agreement</title>
  <style>
    h1 {
      font-size: 18pt;
      margin-bottom: 24px;
    }

    p {
      font-size: 11pt;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 24px;
    }
  </style>
</head>
<body>
  <div>
    ${this.content}
  </div>
</body>
</html>
      `
    }
  }
}
</script>
