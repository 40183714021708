<template>
  <markdown :text="rules" />
</template>
<script>
import Markdown from '../Markdown'
export default {
  name: 'ElectronicSignatureRules',
  components: { Markdown },
  computed: {
    rules () {
      return `# ELECTRONIC RECORD AND SIGNATURE DISCLOSURE
Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to this Electronic Record and Signature Disclosure (ERSD), please confirm your agreement by selecting the check-box next to ‘I agree to use electronic records and signatures’ before clicking ‘CONTINUE’ within the system.

## Getting paper copies
At any time, you may request from us a paper copy of any record provided or made available electronically to you by us.

## Withdrawing your consent
If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and tell us that thereafter you want to receive required notices and disclosures only in paper format.

## Consequences of changing your mind
If you elect to receive required notices and disclosures only in paper format, it will slow the speed at which we can complete certain steps in transactions with you and delivering services to you because we will need first to send the required notices or disclosures to you in paper format, and then wait until we receive back from you your acknowledgment of your receipt of such paper notices or disclosures.

## Acknowledging your access and consent to receive and sign documents electronically
To confirm to us that you can access this information electronically, which will be similar to other electronic notices and disclosures that we will provide to you, please confirm that you have read this ERSD, and (i) that you are able to print on paper or electronically save this ERSD for your future reference and access; or (ii) that you are able to email this ERSD to an email address where you will be able to print on paper or save it for your future reference and access. Further, if you consent to receiving notices and disclosures exclusively in electronic format as described herein, then select the check-box next to ‘I agree to use electronic records and signatures’ before clicking ‘CONTINUE’ within the DocuSign system.
By selecting the check-box next to ‘I agree to use electronic records and signatures’, you confirm that:
* You can access and read this Electronic Record and Signature Disclosure; and
* You can print on paper this Electronic Record and Signature Disclosure, or save or send this Electronic Record and Disclosure to a location where you can print it, for future reference and access; and
* Until or unless you opt out of singing electronically, you consent that your digital signature shall act as a physical signature in the same legal manner.
`
    }
  }
}
</script>
