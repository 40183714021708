<template>
  <document>
    <template #toolbar>
      <download-pdf v-if="htmlString" :content="htmlString" />
    </template>

    <div v-if="text">
      <markdown :text="text" @parsed="markdownParsed" />
    </div>
  </document>
</template>
<script>
import Document from './Document'
import DownloadPdf from './Toolbar/DownloadPdf'
import Markdown from '../Markdown'
export default {
  name: 'MarkdownDocument',
  components: { Document, DownloadPdf, Markdown },
  props: {
    text: {
      required: true,
      type: String
    }
  },
  data: () => ({
    htmlString: null
  }),
  methods: {
    markdownParsed (html) {
      this.htmlString = html
    }
  }
}
</script>
