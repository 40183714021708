<style>
#document {
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.45);
  padding: 25px;
  max-height: 600px;
  overflow-y: auto;
}
</style>
<template>
  <div>
    <v-toolbar dense flat style="border: 1px solid #d7d7d7">
      <slot name="toolbar"></slot>
    </v-toolbar>

    <div id="document">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Document'
}
</script>
